// text selection appearance
::selection {
  color: white;
  background: $blue-green;
}

/***************************** SCROLLBAR STYLES *****************************/

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: $offwhite;
}

::-webkit-scrollbar-thumb {
  /* background: linear-gradient(150deg, #1c73a5 0%, #29a284 50%, #a5d060); */
  background: linear-gradient($blue 0%, $blue-green 50%, $yellow-green);
  border: 2px solid $offwhite;
}

::-webkit-scrollbar-thumb:hover {
  background: $charcoal;
}