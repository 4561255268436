@import './styles/themes';
@import './styles/effects';

/***************************** BASE/GLOBAL STYLES *****************************/

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  font-weight: 300;
}

a {
  text-decoration: none;
}

section {
  padding: 20px;

  h2 {
    margin-bottom: .25em;
  }
}

footer {
  padding: 20px;
}

/***************************** MEDIA QUERIES *****************************/

@media only screen and (min-width: 1024px) {
  section, footer {
    padding: 20px 15%;
  }
}

@media only screen and (min-width: 1440px) {
  section, footer {
    padding: 20px 15%;
  }
}