.card {
  background-color: #ddd;
  color: #222;
  font-size: .85em;
  font-weight: normal;
  margin: .5em;
  border-radius: 3px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);

  flex-basis: 22%;
  flex-shrink: 0;
  flex-grow: 1;
  text-align: center;

  min-height: 200px;
  min-width: 250px;
  max-width: 90%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  justify-content: space-around;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }

  p {
    display: inline-block;
    padding: .5em 0;
    margin: 0;
    width: 100%;
    background-color: rgba(200,200,200,0.95);
    color: rgba(0,0,0,0.8);
    border-radius: 0 0 3px 3px;
    flex-grow: 1;
  }
}

@media only screen and (min-width: 601px) {
  .card {
    max-width: 48.5%;
  }
}