 .pli {
   display: flex;

   &--icon {
     display: flex;
     flex-direction: column;
     justify-content: center;

     svg {
       position: relative;
       top: 0;
     }
   }

   &--children {
     display: flex;
     flex-direction: column;
     justify-content: center;

     p {
       margin: 0;
     }
   }
 }