h2 {
  text-align: center;
}

@media (min-width: 768px) {
  h2 {
    text-align: left;
  }
}

.section-subheading {
  margin: 0 0 1em 0;
  font-size: .85em;
}