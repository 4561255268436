

.project-section {
  h2 {
    padding-bottom: 10px;
  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .project-section {
    .section-wrapper {
      flex-direction: row;
    }
  }
}