#personal-pics {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 30px;

  img {
    max-width: 20%;
    border-radius: 50%;
    border: 1px solid #aaa;
    padding: 5px;
    margin: 20px 0;

    &:first-child {
      max-width: 80%;
    }
  }
}

@media only screen and (min-width: 601px) {
  #personal-pics img, #personal-pics img:first-child {
    max-width: 150px;
  }
}
