@import './colors';

.theme-dark {
  background-color: $charcoal;
  color: white;

  a {
    color: $yellow-green;
  }

  .MuiSvgIcon-root {
    color: white;
  }
}

.theme-light {
  background: $offwhite;
  color: $charcoal;

  a {
    color: $blue-green;
  }

  .MuiSvgIcon-root {
    color: $charcoal;
  }
}

.theme-gradient-blue { 
  background: linear-gradient(150deg, $blue, $blue-green); 
}

.theme-gradient-green { 
  background: linear-gradient(150deg, $blue-green, $yellow-green); 
}

.theme-gradient-blue, .theme-gradient-green {
  color: white;

  a {
    color: $charcoal;
  }
}

