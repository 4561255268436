@import '../../styles/colors';

header {
  text-align: center;
  box-shadow: 0 0 5px 1px rgba(0,0,0,0.3);
  /* the following lines allow the drop shadow to display*/
  position: relative;
  z-index: 10;
  padding: 0;
  margin: 0;

  nav ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    padding: 10px;

    a {
      color: $yellow-green-dark;
    }

    li {
      display: inline;
      font-size: 1.4em;
      padding: 20px;
    }
  }
}

#personal-brand-wrapper {
  font-size: 1rem;
}

#personal-brand {
  padding: 0 20px;
}

#personal-name {
  font-weight: normal;
  margin: 0;
  color: #0c9b77;
}

#personal-title {
  color: #0c679b;
}

#logo {
  padding: 10px 0 0 0;

  img {
    max-height: 50px;
  }
}

#hamburger-menu {
  color: #ddd;
  font-size: 3rem;
  padding: .5rem 0 0 0;
  display: inline;
  float: left;
}

@media only screen and (max-width: 600px) {
  header nav ul li {
    padding: 0.6em;
  }

  nav .collapsed {
    max-height: 0px;
    padding: 0;
    opacity: 0;
    transition: height 250ms, padding 250ms, opacity 250ms;
  }

  nav li {
    transition: height 250ms, padding 250ms, opacity 250ms;
  }
}

@media only screen and (min-width: 601px) {
  header nav ul {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  #hamburger-menu {
    display: none;
  }
}