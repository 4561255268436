#back-to-top {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  display: block;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  border-radius: 10%;
  border-bottom: 3px solid rgba(0,0,0,0.3);
  padding: 5px;
  
  svg {
    color: rgba(0,0,0,0.3);
    
  }
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.invisible {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
}